import ready from "heliosx-common/dist/Ready";
import { sendCurrentPageEvent } from "./MedexpressAmplitude";
import { setCookie } from "../Utils/Cookies";

export * from "./MedexpressAmplitude";
export * from "../Utils/Cookies";

const DAY_IN_SECONDS = 86_400;
const MONTH_IN_SECONDS = 30 * DAY_IN_SECONDS;

function loadPixel() {
  const pixelElement = document.querySelector('[data-js-bind="medexpress-pixel"]');
  if (pixelElement == null) return;

  const url = new URL(document.location);
  const old = url.searchParams;
  const endpoint = new URL(pixelElement.dataset.src, document.location);

  const paramsToHide = ["promo-code", "clickref", "awc", "redirected-from"];

  //N.B This deliberately doesn't wait for the promise to finish, so that we drop the parameters quickly.
  fetch(endpoint);

  //Prefer the parameter here as it is fresh.
  setDiscountCookie(pixelElement, old.get("promo-code"));
  setClickRefCookie(old.get("clickref"));
  setAwinClickRefCookies(old.get("awc"));

  dropSearchParams(url.searchParams, paramsToHide);

  window.history.replaceState({}, "", url.toString());
}

function setDiscountCookie(pixelElement, promoParam) {
  const discountCode = promoParam || pixelElement.dataset.discountCode;
  if (!discountCode) return;
  setCookie("medexpress-discount", discountCode);
}

function setClickRefCookie(clickRefParam) {
  if (!clickRefParam) return;
  setCookie("click-ref", clickRefParam, MONTH_IN_SECONDS);
}

function setAwinClickRefCookies(awinClickRefParam) {
  if (!awinClickRefParam) return;
  setCookie("awinclickref", awinClickRefParam, MONTH_IN_SECONDS * 3);
  setCookie("awinclickrefdate", new Date().toISOString(), MONTH_IN_SECONDS * 3);
}

function dropSearchParams(searchParams, params) {
  params.forEach((param) => {
    searchParams.delete(param);
  });
}

(function main() {
  ready(sendCurrentPageEvent);
  ready(loadPixel);
})();
