export const setCookie = (name, value, seconds = 3600, path = "/") => {
  document.cookie = name + "=" + encodeURIComponent(value) + expiresString(seconds) + "; path=" + path;
};

function expiresString(seconds) {
  if (seconds == null) return "";
  const expires = new Date(Date.now() + seconds * 1000).toUTCString();
  return "; expires=" + expires;
}

export const getCookie = (name) => {
  return document.cookie.split("; ").reduce((r, v) => {
    const parts = v.split("=");
    return parts[0] === name ? decodeURIComponent(parts[1]) : r;
  }, "");
};

export const deleteCookie = (name, path) => {
  setCookie(name, "", -1, path);
};
